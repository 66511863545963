@import "color-scheme";

ul.timeline {
  padding-top: 5px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.timeline {
  list-style: none;
  padding: 10px 0;
  position: relative;
  font-weight: 300;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: "  ";
  width: 2px;
  background: #ffffff;
  left: 50%;
  margin-left: -1.5px;
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
  width: 50%;
  float: left;
  clear: left;
}

.timeline > li:before, .timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before, .timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: calc(100% - 25px);
  width: -moz-calc(100% - 25px);
  width: -webkit-calc(100% - 25px);
  float: left;
  border: 1px solid #dcdcdc;
  background: #ffffff;
  position: relative;
  padding: 20px;
}

.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #dcdcdc;
  border-right: 0 solid #dcdcdc;
  border-bottom: 15px solid transparent;
  content: " ";
}

.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #ffffff;
  border-right: 0 solid #ffffff;
  border-bottom: 14px solid transparent;
  content: " ";
}

.timeline > li > .timeline-badge {
  color: #ffffff;
  width: 24px;
  height: 24px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 16px;
  right: -12px;
  z-index: 100;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline > li:last-child {
  margin-bottom: 0;
}

.timeline-badge > a {
  color: #ffffff !important;
}

.timeline-badge a:hover {
  color: #dcdcdc !important;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-heading h4 {
  font-weight: 400;
  padding: 0 5px;
  margin: 5px 0 0 0;
  font-size: 24px;
  color: $timeline-title-color;
}

.timeline-heading h5 {
  font-size: 20px;
  line-height: 110%;
  padding: 0 15px 0 5px;
  margin: 5px 0 5px 0;
}

.timeline-heading h5 span {
  margin: 0 0 0 5px;
}

.timeline-heading h6 {
  line-height: 110%;
  margin: 0 0 0 0;
  padding: 0 5px;
  font-size: 15px;
  opacity: 0.7;
}

.timeline-heading img {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  position: relative;
  top: 10px;
}

.timeline-body > p, .timeline-body > ul {
  //padding: 0 10px 15px 15px
  text-align: justify;
  margin-bottom: 0;
}

.timeline-footer {
  padding: 5px 15px;
  background-color: #f4f4f4;
}

.timeline-footer p {
  margin-bottom: 0;
}

.timeline-footer > a {
  cursor: pointer;
  text-decoration: none;
}

.timeline > li.timeline-inverted {
  float: right;
  clear: right;
}

.timeline > li:nth-child(2) {
  margin-top: 60px;
}

.timeline > li.timeline-inverted > .timeline-badge {
  left: -12px;
}

.no-float {
  float: none !important;
}

@media (max-width: 767px) {
  ul.timeline:before {
    left: 40px;
  }

  ul.timeline > li {
    margin-bottom: 0;
    position: relative;
    width: 100%;
    float: left;
    clear: left;
  }

  ul.timeline > li > .timeline-panel {
    width: calc(100% - 65px);
    width: -moz-calc(100% - 65px);
    width: -webkit-calc(100% - 65px);
  }

  ul.timeline > li > .timeline-badge {
    left: 28px;
    margin-left: 0;
    top: 16px;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }

  .timeline > li.timeline-inverted {
    float: left;
    clear: left;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .timeline > li.timeline-inverted > .timeline-badge {
    left: 28px;
  }

  ul.timeline {
    padding-top: 5px;
    right: 27px;
    width: 108%;
  }
}
