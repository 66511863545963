$font-stack: 'Open Sans', sans-serif;
$primary-text: #000;
$contrast-text: #fff;

$primary-color: #2196f3;
$secondary-color: green;

$primary-background: #efefef;
$secondary-background: #fff;
$footer-background: #000;

$error-color: #ff4605;
$warning-color: #f8fe4d;

//Body
$background-color: $primary-background;

//Navbar
$navbar-text: $contrast-text;
$sticky-navbar-background: $primary-color;
$sticky-navbar-text: $contrast-text;

//Sidenav
$sidenav-background: $secondary-background;
$sidenav-text: $primary-text;

//About
$about-container-background: $secondary-background;
$about-name-text-color: $primary-color;
$about-me-title-color: $primary-text;
$about-media-footer-background: $primary-color;
$about-media-footer-icons-color: $contrast-text;

//Expertise
$expertise-icon-color: $primary-color;
$skills-block-background: $secondary-background;
$skills-block-text-color: $primary-text;

//Skills
$skills-block-background: $secondary-background;
$skills-block-text-color: $primary-text;
$skills-block-preloader-background: $primary-background;
$skills-block-preloader-selected: $primary-color;

//Languages
$languages-block-background: $secondary-background;
$languages-block-text-color: $primary-text;
$languages-block-preloader-background: $primary-background;
$languages-block-preloader-selected: $primary-color;

//Timeline
$timeline-title-color: $primary-color;
$timeline-text-color: $primary-text;

//Contact
$contact-block-background: $secondary-background;
$contact-block-text-color: $primary-text;
$contact-form-primary-color: $primary-color;
$contact-form-error-color: $error-color;